<template>
  <div>
    <v-list
      v-if="therapies && therapies.length > 0"
      class="pa-5 pt-0 body-2 mb-3"
    >
      <v-list-item
        v-for="therapy in therapies"
        :key="therapy.id"
        class="border-bottom-grey"
      >
        <v-list-item-content>
          {{ therapy[fieldName] }}
        </v-list-item-content>
        <v-list-item-action>
          {{ therapy.date | formatDateTime }}
        </v-list-item-action>
      </v-list-item>
      <v-list-item :to="{ name: nameRouteDetailed }">
        <v-list-item-content
          class="font-weight-bold secondary--text text-decoration-underline"
        >
          Ver histórico completo
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="secondary">arrow_forward</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <empty-list
      v-else
      class="pa-3"
      text="Nenhum dado disponível para mostrar"
    />
  </div>
</template>

<script>
export default {
  name: "SimplifiedTherapiesList",
  props: {
    therapies: {
      type: Array,
      default: () => [],
    },
    fieldName: {
      type: String,
      default: "patientName",
    },
    nameRouteDetailed: {
      type: String,
      required: true,
    },
  },
  methods: {},
};
</script>

<style scoped></style>
